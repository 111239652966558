import React, { Suspense, useState, useEffect } from "react";
import "./App.scss";
import Footer from "./containers/Footer";
import BottomNav from "./containers/BottomNav";
import { APP_CONST } from "./config/const";

import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import BeforeLoginFooter from "./containers/BeforeLoginFooter";
import ProfitAndLoss from "./views/pages/privatePages/profit&loss/ProfitAndLoss";
import HeaderAfterLogin from "./containers/HeaderAfterLogin";
import ContactUsBefore from "./views/pages/publicPages/FooterPages/ContactUsBefore";
import Providers from "./views/pages/privatePages/providers/Providers";
import Rummy from "./views/pages/privatePages/rummy/Rummy";
import LoadingImg from "./assets/images/loader.gif";
import FirstGamePage from "./views/pages/privatePages/gamesPage/FirstGamePage";
import SecondGamePage from "./views/pages/privatePages/gamesPage/SecondGamePage";
import ThirdGamePage from "./views/pages/privatePages/gamesPage/ThirdGamePage";
import FourthGamePage from "./views/pages/privatePages/gamesPage/FourthGamePage";
import AllGamePage from "./views/pages/privatePages/gamesPage/AllGamePage";

import SupportCustomer from "./views/pages/privatePages/support-customer/SupportCustomer";
import LiveDealer from "./views/pages/privatePages/gamesPage/LiveDealer";
// import Termsandcondition from "./containers/Termsandcondition";
import GatewaysList from "./views/pages/privatePages/deposit/gatewaysList";
import DepositGateway from "./views/pages/privatePages/deposit/depositGateway";
import Bonus from "./views/pages/privatePages/bonus";
import BonusCard from "./views/pages/privatePages/bonus/bonusCard";
import Referral from "./views/pages/privatePages/Referral";
import PublicSports from "./views/pages/publicPages/publicSports";
import LiveDealerPublic from "./views/pages/publicPages/LiveDealerPublic";
import Slots from "./views/pages/privatePages/Slots";

// import Fairplay from "./containers/Fairplay";
const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));
const Withdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdraw")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const ExchangeSPMSportsLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeSPMSportsLobbyPage")
);
const MatchDetailPage = React.lazy(() =>
  import("./views/pages/privatePages/sports/matchDetailPage")
);
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const Support = React.lazy(() => import("./views/pages/privatePages/support"));
const Contact = React.lazy(() => import("./views/pages/privatePages/contact"));

const ResponsibleGaming = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/ResponsibleGaming")
);

const PrivacyPolicy = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/PrivacyPolicy")
);

const WithdrawList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/WithdrawList")
);
const Fairplay = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/Fairplay")
);
const GameRules = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/GameRules")
);
const Betby = React.lazy(() => import("./views/pages/privatePages/betby"));

const Termsandcondition = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/Termsandcondition")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword/resetPassword")
);
const Maintenance = React.lazy(() =>
  import("./views/pages/publicPages/Maintenance")
);
const Kingmaker = React.lazy(() =>
  import("./views/pages/privatePages/Kingmaker")
);

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails, organisationStatus } = useSelector((state) => state.app);

  //const maxToast = 1;
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
    document.canonical = appDetails?.LOGO_URL;
    // check organisation status
    if (
      organisationStatus !== null &&
      organisationStatus !== APP_CONST.ORGANISATION_STATUS.Live &&
      window.location.pathname.indexOf("maintenance") < 0
    ) {
      window.location.href = "/maintenance";
    }
  }, [appDetails]);

  const [isClassAdded, setIsClassAdded] = useState(false);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
    dispatch(getAppDetails());
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const setVhUnit = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  useEffect(() => {
    setVhUnit();
    window.addEventListener("resize", setVhUnit);
    return () => {
      window.removeEventListener("resize", setVhUnit);
    };
  }, []);

  return (
    <Router>
      <Suspense
        fallback={
          <div className="loading_img">
            <img src={LoadingImg} alt="loading" width={80} />
          </div>
        }
      >
        <Routes>
          {/* <Route path="/" element={<Sports isAuth={isAuth} />} /> */}
          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                <Footer />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/maintenance"
            element={<Maintenance organisationStatus={organisationStatus} />}
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateway />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gatewaylist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GatewaysList />
                <Footer />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route path="/betby" element={<Betby isAuth={isAuth} />} />

          <Route
            path="/bonus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bonus />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus/:id"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BonusCard />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/referral"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Referral />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          />
          <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route path="/sports" element={<Sports isAuth={isAuth} />} />

          <Route
            // path="/sports/exchange"
            path="/sportsbook/:gameId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/kingmaker"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Kingmaker />
              </ProtectedRoutes>
            }
          />
          <Route
            // path="/sports/exchange"
            path="/spmsports"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeSPMSportsLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            // path="/sports/exchange"
            path="/inplaySportsbook"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/support"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Support />

                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Contact />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/withdrawList"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/ResponsibleGaming"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ResponsibleGaming />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PrivacyPolicy />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fairplay"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Fairplay />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/gamerules"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GameRules />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacypolicys"
            element={
              <PublicRoutes isAuth={isAuth}>
                <PrivacyPolicy />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/termsandcondition"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Termsandcondition />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/contacts"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ContactUsBefore />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/ResponsibleGamings"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResponsibleGaming />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/termsandconditions"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Termsandcondition />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/gameruless"
            element={
              <PublicRoutes isAuth={isAuth}>
                <GameRules />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/fairplays"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Fairplay />
                <BottomNav />
              </PublicRoutes>
            }
          />
          <Route
            path="/profit&loss"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ProfitAndLoss />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/detail-page/:slug/:matchId/:marketId/:sportId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MatchDetailPage />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/support-customer"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SupportCustomer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/show-all-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Providers />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/first-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FirstGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/second-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SecondGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/third-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ThirdGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fourth-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FourthGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/all-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <AllGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/live-rummy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Rummy />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/live-dealer"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <LiveDealer />
              </ProtectedRoutes>
            }
          />
          <Route path="/public-inplay" element={<PublicSports />} />
          <Route path="/public-sports" element={<PublicSports />} />
          <Route
            path="/"
            element={
              <PublicRoutes>
                <Sports isAuth={isAuth} />
                <BottomNav />
              </PublicRoutes>
            }
          />
          <Route
            path="/live-dealer-public"
            element={
              <PublicRoutes>
                <LiveDealerPublic />
              </PublicRoutes>
            }
          />
          <Route
            path="/Slots"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Slots />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Suspense>
      <ToastContainer
        closeButton={false}
        position="top-right"
        autoClose={1000}
        // limit={4}
      />
    </Router>
  );
};

export default App;
