import React from "react";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { useSelector } from "react-redux";

import ContactUsImg from "../../../../assets/images/contact1.png";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BackIcon from "../../../../assets/svg/BackIcon";
import Facebook512 from "../../../../assets/images/facebook-512.svg";

const BeforeContactUs = ({ isAuth = false }) => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>
      <div>
        <main className="main mt-3 footer-content">
          <div className="container">
            <div className="pagesHeading">
              <h2 className="sectionTitle border-0">Contact Us</h2>
              <a className="back-link" href="/">
                Back <BackIcon />
              </a>
            </div>
            <div className="payment-info contact-pay-info">
              <div className="contact-img">
                <img src={ContactUsImg} />
              </div>
              <div class="social-icons contact-social">
                <ul>
                  <li>
                    <a
                      href={
                        appDetails?.TELEGRAM != ""
                          ? appDetails?.TELEGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Telegram} alt="Telegram Icon" />
                      <p> Telegram</p>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails?.INSTATGRAM != ""
                          ? appDetails?.INSTATGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Instagram} alt="Instagram Icon" />
                      <p>Instagram</p>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails?.FACEBOOK != ""
                          ? appDetails?.FACEBOOK
                          : "javascript:void(0)"
                      }
                      target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                    >
                      <img src={Facebook512} alt="Facebook Icon" />
                      <p>Facebook</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default BeforeContactUs;
