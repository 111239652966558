import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import { useNavigate } from "react-router-dom";

import BackIcon from "../../../../assets/svg/BackIcon";
import EvolutionGames from "../../../../assets/images/provider/provider-logo-evolution.png";
import EzugiGames from "../../../../assets/images/provider/provider-logo-ezugi.png";
import AppuGames from "../../../../assets/images/provider/Supernowa.jpg";
import BottomNav from "../../../../containers/BottomNav";
import Header from "../../../../containers/Header";
import XPG from "../../../../assets/images/provider/provider-logo-XPG.jpg";
import WorldCasino from "../../../../assets/images/provider/provider-logo-Worldcasino.jpg";
import Qtech from "../../../../assets/images/provider/provider-logo-Qtech.jpg";
import Vivo from "../../../../assets/images/provider/provider-logo-vivo.jpg";

const LiveDealerPublic = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />

      <main className="main">
        <div className="container">
          <div className="live-deal-section">
            <div className="pagesHeading">
              <h2 className="sectionTitle border-0">Live Dealer</h2>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>
            <ul>
              <li>
                <a href="/casino/evolution">
                  <img src={EvolutionGames} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/ezugi">
                  <img src={EzugiGames} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/supernowa">
                  <img src={AppuGames} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/xpg">
                  <img src={XPG} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/worldcasino">
                  <img src={WorldCasino} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/qtech">
                  <img src={Qtech} alt="Providers Logo" />
                </a>
              </li>
              <li>
                <a href="/casino/vivo">
                  <img src={Vivo} alt="Providers Logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <Footer />
      <BottomNav />
    </div>
  );
};

export default LiveDealerPublic;
