// import game1 from "../../../../assets/images/casino/TeenPatti.jpg";
import game1 from "../../assets/images/casino/TeenPatti.jpg";
import game2 from "../../assets/images/casino/TeenPatti20-20.jpg";
import game3 from "../../assets/images/casino/AndarBahar.jpg";
import game4 from "../../assets/images/casino/32Cards.jpg";
import game5 from "../../assets/images/casino/Roulette.jpg";
import game6 from "../../assets/images/casino/AkbarRomeoWalter.jpg";
import game7 from "../../assets/images/casino/Cricketwar.jpg";
import game8 from "../../assets/images/casino/Sicbo.jpg";
import game9 from "../../assets/images/casino/Baccarat.jpg";
import game10 from "../../assets/images/casino/WorliMatka.jpg";
import game11 from "../../assets/images/casino/DragonTiger.jpg";
import game12 from "../../assets/images/casino/OneDayTeenPatti.jpg";
import game13 from "../../assets/images/casino/KingRace.jpg";
import game14 from "../../assets/images/casino/CAsinoQueen.jpg";
import game15 from "../../assets/images/casino/ClassicAndarBahar.jpg";
import game16 from "../../assets/images/casino/Goa'sAndarBahar.jpg";
import game17 from "../../assets/images/casino/Lucky7.jpg";
import game18 from "../../assets/images/casino/Poker.jpg";
import game20 from "../../assets/images/casino/RNG3CardsJudgement.jpg";
import game21 from "../../assets/images/casino/RNG3-PlayerTeenPatti.jpg";
import game22 from "../../assets/images/casino/RNG32Cards.jpg";
import game23 from "../../assets/images/casino/RNGAkbarRomeoWalter.jpg";
import game24 from "../../assets/images/casino/RNGAndarBahar2020.jpg";
import game25 from "../../assets/images/casino/RNGBaccarat.jpg";
import game26 from "../../assets/images/casino/RNG-Baccarat-2020.jpg";
import game27 from "../../assets/images/casino/RNGCasinoQueen.jpg";
import game28 from "../../assets/images/casino/RNGCasinoQueen2020.jpg";
import game29 from "../../assets/images/casino/RNgCricket2020.jpg";
import game30 from "../../assets/images/casino/RNGDragonTiger.jpg";
import game31 from "../../assets/images/casino/RNGDragonTiger2020.jpg";
import game32 from "../../assets/images/casino/RNGJoker.jpg";
import game33 from "../../assets/images/casino/RNGKabutarFuddi.jpg";
import game34 from "../../assets/images/casino/RNGPoker.jpg";
import game35 from "../../assets/images/casino/RNGPoker2020.jpg";
import game36 from "../../assets/images/casino/RNGTeenPatti.jpg";
import game37 from "../../assets/images/casino/RNGTeenPatti2020.jpg";
import game38 from "../../assets/images/casino/TeenPattiFaceOff.jpg";

import populargame1 from "../../assets/images/casino/popular/CashOrCrash.jpg";
import populargame2 from "../../assets/images/casino/popular/CrazyTime.jpg";
import populargame3 from "../../assets/images/casino/popular/DragonTiger.jpg";
import populargame4 from "../../assets/images/casino/popular/DreamCatcher.jpg";
import populargame5 from "../../assets/images/casino/popular/FootballStudio.jpg";
import populargame6 from "../../assets/images/casino/popular/GonzosTreasureHunt.jpg";
import populargame7 from "../../assets/images/casino/popular/MegaBall.jpg";
import populargame8 from "../../assets/images/casino/popular/OttAndarBahar.jpg";

export const gamesData = [
  {
    name: "Teen Patti",
    redirectUrl: "/casino/ezg-teen-patti-3-card",
    img: <img src={game1} />,
  },
  {
    name: "Bet on Teen Patti",
    redirectUrl: "/casino/ezg-bet-on-teen-patti",
    img: <img src={game2} />,
  },
  {
    name: "Andar Bahar",
    redirectUrl: "/casino/ezg-andar-bahar",
    img: <img src={game3} />,
  },
  {
    name: "32 Cards",
    redirectUrl: "/casino/ezg-32-cards",
    img: <img src={game4} />,
  },
  {
    name: "spanish roulette",
    redirectUrl: "/casino/ezg-spanish-roulette",
    img: <img src={game5} />,
  },
  {
    name: "akbar romeo walter",
    redirectUrl: "/casino/sn-akbar-romeo-walter",
    img: <img src={game6} />,
  },
  {
    name: "Cricket war",
    redirectUrl: "/casino/ezg-cricket-war",
    img: <img src={game7} />,
  },
  {
    name: "Sicbo",
    redirectUrl: "/casino/ezg-sic-bo",
    img: <img src={game8} />,
  },
  {
    name: "Baccarat",
    redirectUrl: "/casino/ezg-baccarat",
    img: <img src={game9} />,
  },
  {
    name: "Worli matka",
    redirectUrl: "/casino/sn-worli-matka",
    img: <img src={game10} />,
  },
  {
    name: "Dragon tiger",
    redirectUrl: "/casino/ezg-dragon-tiger",
    img: <img src={game11} />,
  },
  {
    name: "One Day Teen patti",
    redirectUrl: "/casino/ezg-one-day-teen-patti",
    img: <img src={game12} />,
  },
  {
    name: "King race",
    redirectUrl: "/casino/sn-king-race",
    img: <img src={game13} />,
  },
  {
    name: "Casino queen",
    redirectUrl: "/casino/sn-rng-casino-queen",
    img: <img src={game14} />,
  },
  {
    name: "Classic Andar bahar",
    redirectUrl: "/casino/sn-classic-andar-bahar",
    img: <img src={game15} />,
  },
  {
    name: "Goas Andar bahar",
    redirectUrl: "/casino/sn-goas-andar-bahar",
    img: <img src={game16} />,
  },
  {
    name: "Lucky 7",
    redirectUrl: "/casino/ezg-lucky-7",
    img: <img src={game17} />,
  },
  {
    name: "3 cards judgement",
    redirectUrl: "/casino/sn-rng-3-cards-judgement",
    img: <img src={game20} />,
  },
  {
    name: "3 player Teenpatti",
    redirectUrl: "/casino/sn-rng-3-player-teen-patti",
    img: <img src={game21} />,
  },
  {
    name: "32 cards",
    redirectUrl: "/casino/sn-rng-32-cards",
    img: <img src={game22} />,
  },
  {
    name: "Akbar romeo walter",
    redirectUrl: "/casino/sn-rng-akbar-romeo-walter",
    img: <img src={game23} />,
  },
  {
    name: "Baccarat",
    redirectUrl: "/casino/sn-rng-baccarat",
    img: <img src={game25} />,
  },
  {
    name: "Casino queen",
    redirectUrl: "/casino/sn-rng-casino-queen",
    img: <img src={game27} />,
  },
  {
    name: "Casino queen 2",
    redirectUrl: "/casino/sn-rng-casino-queen-2",
    img: <img src={game28} />,
  },
  {
    name: "Cricket 2020",
    redirectUrl: "/casino/sn-rng-cricket-2020",
    img: <img src={game29} />,
  },
  {
    name: "dragon tiger",
    redirectUrl: "/casino/sn-rng-dragon-tiger",
    img: <img src={game30} />,
  },
  {
    name: "dragon tiger 2020",
    redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
    img: <img src={game31} />,
  },
  {
    name: "Joker",
    redirectUrl: "/casino/sn-rng-joker",
    img: <img src={game32} />,
  },
  {
    name: "kabutar fiddi",
    redirectUrl: "/casino/sn-rng-kabutar-fuddi",
    img: <img src={game33} />,
  },
  {
    name: "poker",
    redirectUrl: "/casino/sn-rng-poker",
    img: <img src={game34} />,
  },
  {
    name: "poker 2020",
    redirectUrl: "/casino/sn-rng-poker-2020",
    img: <img src={game35} />,
  },
  {
    name: "teen patti",
    redirectUrl: "/casino/sn-rng-teen-patti",
    img: <img src={game36} />,
  },
  {
    name: "teen patti 2020",
    redirectUrl: "/casino/sn-rng-teen-patti-2020",
    img: <img src={game37} />,
  },
  {
    name: "teen patti Face off",
    redirectUrl: "/casino/sn-teen-patti-face-off",
    img: <img src={game38} />,
  },
  {
    name: "cash or crash",
    redirectUrl: "/casino/ezgevo-cash-or-crash",
    img: <img src={populargame1} />,
  },
  {
    name: "Crazy time",
    redirectUrl: "/casino/ezgevo-crazy-time",
    img: <img src={populargame2} />,
  },
  {
    name: "Dragon Tiger",
    redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
    img: <img src={populargame3} />,
  },
  {
    name: "dream catcher",
    redirectUrl: "/casino/ezgevo-dream-catcher",
    img: <img src={populargame4} />,
  },
  {
    name: "football studio",
    redirectUrl: "/casino/ezgevo-football-studio",
    img: <img src={populargame5} />,
  },
  {
    name: "gonzos quest megaways",
    redirectUrl: "/casino/ezgrt-gonzos-quest-megaways",
    img: <img src={populargame6} />,
  },
  {
    name: "mega ball",
    redirectUrl: "/casino/ezgevo-first-person-mega-ball",
    img: <img src={populargame7} />,
  },
  {
    name: "marina andar bahar",
    redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
    img: <img src={populargame8} />,
  },
];
