import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/games-icon/leftbar/Home.png";
import AviatorIcon from "../assets/images/games-icon/leftbar/Aviator.png";
import CasinoIcon from "../assets/images/games-icon/leftbar/WorldCasino.png";
import InplayIcon from "../assets/images/games-icon/leftbar/In-play.png";
import CricketIcon from "../assets/images/games-icon/leftbar/Cricket.png";
import SoccerIcon from "../assets/images/games-icon/leftbar/Soccer.png";
import TennisIcon from "../assets/images/games-icon/leftbar/Tennis.png";
import HorseRacingIcon from "../assets/images/games-icon/leftbar/Horse.png";
import MinesIcon from "../assets/images/icons/header/mines.png";

import WalletIcon from "../assets/images/WalletIcon.png";
import UserIcon from "../assets/images/user-icon.svg";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import LogoutIconImg from "../assets/images/logout.png";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";
import ArrowDown from "../assets/images/arrow-down.png";

const MobLeftbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const { user, balance, wallet } = useSelector((state) => state.auth);

  return (
    <main className="main mobleftbarPage">
      <div className="leftbarSec">
        {isAuth && (
          <>
            <div className="d-flex profilemobsection">
              <div className="profile-img">
                <img src={UserIcon} alt="User Icon" />
              </div>
              <div className="user-info">
                <Link className="user_name" to="/profile">
                  {user?.mstruserid}
                </Link>
                <p>{user?.mobileno}</p>
              </div>
            </div>
            <div className="container upperbalnace">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-4">
                    <img src={WalletIcon} alt="wallet" />
                    Balance
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-4">
                    {" "}
                    INR {wallet?.balance}
                  </div>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                >
                  <Button variant="" className="btn_withdraw w-100">
                    Withdraw
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/gatewaylist");
                  }}
                >
                  <Button variant="primary" className="btn_primary w-100">
                    Deposit
                  </Button>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/bonus");
                  }}
                >
                  <Button variant="" className="btn_withdraw mt-2 w-100">
                    Bonus
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/mybets");
                  }}
                >
                  <Button variant="" className="btn_withdraw  mt-2 w-100">
                    My Bets
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}

        <div>
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  navigate("/sports");
                }}
              >
                <img src={HomeIcon} alt="Home Icon" />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  navigate("/casino/spribe/aviator");
                }}
              >
                <img src={AviatorIcon} alt="Inplay Icon" />
                <span>Aviator</span>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  navigate("/casino/spribe/mines");
                }}
              >
                <img src={MinesIcon} alt="Inplay Icon" />
                <span>Mines</span>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  navigate("/sportsbook/Inplay", {
                    state: { selectedTab: "1" },
                  });
                }}
              >
                <img src={InplayIcon} alt="Inplay Icon" />
                <span>In-play</span>
              </a>
            </li>
            {/* <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  navigate("/casino/worldcasino");
                }}
              >
                <img src={CasinoIcon} alt="Casino Icon" />
                <span>World Casino</span>
              </a>
            </li> */}
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  navigate("/sportsbook/Cricket", {
                    state: { selectedTab: "2" },
                  });
                }}
              >
                <img src={CricketIcon} alt="Cricket Icon" />
                <span>Cricket</span>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  navigate("/sportsbook/Soccer", {
                    state: { selectedTab: "3" },
                  });
                }}
              >
                <img src={SoccerIcon} alt="Soccer Icon" />
                <span>Soccer</span>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  navigate("/sportsbook/Tennis", {
                    state: { selectedTab: "4" },
                  });
                }}
              >
                <img src={TennisIcon} alt=" Icon" />
                <span>Tennis</span>
              </a>
            </li>

            <li>
              <a href="/sportsbook/Horse%20Racing">
                <img src={HorseRacingIcon} alt="horseracing Icon" />
                <span>Horseracing</span>
              </a>
            </li>
          </ul>

          <ul>
            <h6>
              <span>My Account</span>
              <img src={ArrowDown} alt="down arrow" />
            </h6>

            <li>
              <a href="/mybets">
                <img src={BetIcon} alt="my bet" />
                <span>My Bets</span>
              </a>
            </li>

            <li>
              <a href="/bonus" className="img_size">
                <img src={BonusImg} />
                <span className="bonus-space">Bonus</span>
              </a>
            </li>
            <li>
              <a href="/referral" className="img_size">
                <img src={refferal} />

                <span className="bonus-space">Referral</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <img src={AcStatement} alt="account statement" />
                <span>Account Statement</span>
              </a>
            </li>
            {isAuth && (
              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  {/* <LogoutIcon /> */}
                  <img src={LogoutIconImg} />
                  <span>Sign Out</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default MobLeftbar;
