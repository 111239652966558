import React from "react";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import BottomNav from "../../../../containers/BottomNav";

const PublicSports = () => {
  return (
    <>
      <Header />

      <div className="exchange_iframe">
        <iframe
          src="https://design.powerplay247.com/auth"
          style={{
            width: "100%",
            height: "100vh",
            maxHeight: "calc(100vh - 100px)",
            // aspectRatio: "16/9",
          }}
        />
      </div>

      <Footer />
      <BottomNav />
    </>
  );
};
export default PublicSports;
